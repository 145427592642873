@charset "utf-8";

/*
html{
	overflow: hidden;
}
*/
@media (min-width: 992px){
.container {
	width: 1000px !important;
	}
}
.page-header{ 
	    margin-top: 84px;
        @media screen and (max-width:768px){
            margin-top: 112px;
        }
}
.navbar {
    position: fixed;
    top: 0;
    left: 0;
	height: 10px;
	@media screen and (max-width:768px){
		height: 110px;
	}
}    
.CUSTUM-HEADER{
	@media screen and (min-width:769px){
		box-shadow: none;
		height: 100px;
		h1{
			width: 180px;
			margin: 16px 0 0;
			img{
				width: 100%; 
			}
			&+div{
				display: none;
			}
		} 
		.siteHeadContainer{
			padding-top: 5px !important;
			padding-bottom: 5px !important;
		}
		.gMenu{
			margin: 14px 0 0;
			> li > a{
                padding: 10px 12px;
                font-size: 12px;
			}
			> li {
			    line-height: 4;
				&:last-child{
					width: 120px;
					height: 80px;
					background: $green;
					a,
					a:before{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						overflow: hidden;
						text-indent: -9999px;
					}
					a{
						&:before{
							content: '';
							z-index: 10;
							background: url(/images/common/contact_bg.jpg);
						}

					}
					&:after{
						display: none;
					}
				}
			}
		}
	}
	@media screen and (max-width:768px){
		h1{
			width: 200px;
			margin: 4px 0;
			img{
				width: 100%;
			}
			&+div{
				display: none;
			}
		}
	}
}

.vk-mobile-nav-menu-btn{display: none;}
@media screen and (max-width:992px){
	.vk-mobile-nav-menu-btn{
		display: block;
		top: 15px;
		right: 15px;
		left: auto;
	}
}
a#menuBtn{
	display: none;
}


/*
#HEADER {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 80px;
	background: rgba(255,255,255,.96);
	padding: 0 30px;
	z-index: 100;
	@media screen and (max-width:768px){
		height: 60px;
		padding: 0;
	}
	a {
		color: #111;
	}
	.logo {
		position: absolute;
		top: 16px;
		left: 60px;
		width: 250px;
		@media screen and (max-width:768px){
			top: 8px; 
			left: 12px;
		}
		img {
			max-width: 240px;
			height: auto;
			@media screen and (max-width:768px){
				max-width: 73px;
			}
		}
		span {
			display: inline-block;
			margin-left: 12px;
			font-weight: 700;
		}
	}
	
	.gnavi {
            float: right;
		@media screen and (max-width:768px){
			display: block;
			width: 32px;
			height: 32px;
			background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;

			margin:14px 12px 0 0;
			cursor:pointer;
			&.addClose {
				background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;

				ul {
					border-top: 1px solid #eee;
				}
			}
		}
		ul {
			margin: 0 !important;
			height: 80px;
			@media screen and (max-width:768px){
				display: none;
				position: absolute;
				top: 60px;
				left: 0;
				width: 100%;
			}
			>li {
				float: left;
				width: 154px;
				list-style: none;
				line-height: 80px;
				@media screen and (max-width:768px){
					float: none;
					width: 100% !important;
					background: rgba(255,255,255,.96);
					text-align: left;
					border-bottom: 1px solid #d5d5d5;
				}
				&:last-child{
					width: 100px;
					background: $darkgreen;
					a{
						color: #fff;
                        margin-top: 18px;
                        height: 62px;
						position: relative;
                        &::before {
                            font-family: "Font Awesome 5 Free";
                            content: '\f0e0';
                            font-weight: 900;
                            display: block;
                            text-align: center;
                            font-size: 24px; 
                            color: #FFFFFF;
                            position: absolute;
                            top: -28px;
                            left: 37px;
                        }
					}
				}
				a,.link {
					display: block;
					text-align: center;
					font-size: 14px;
					font-weight: 700;
					position: relative;
					cursor: pointer;
					@media screen and (max-width:768px){
						font-size: 14px;
						padding: 12px 0;
					}

					&:hover {
						&:after {
						  margin-left:-50px;
						  width:100px;
						}
					}
					
					
				}
				&.acd {
					position: relative;
					.trigger{
						cursor:pointer;
						@media screen and (max-width:768px){
							background-image: url("../../images/common/icon_plus_02.png");
							background-position: right 12px center;
							background-repeat: no-repeat;
						}
					}			

                }
            }
        }
    }
}

*/