#WRAPPER{
	max-width: 1920px;
	margin: auto;
}
#firstView {
    background: url(/images/index/firstview07.jpg) no-repeat;
    background-position: initial;
    background-size: inherit;
	margin-top: 62px; 
	.display{ 
		max-width: 1300px;
	}
    .mask{
        @media screen and (max-width:768px){
            background: rgba(255,255,255,.8);  
        }
    }
	dl{
        float: right;
        margin-top: 60px;
        margin-bottom: 60px;
        position: relative;
        height: 330px;
        @media screen and (max-width:768px){
			height: 380px;
			margin: 0;
        }

		dt{
			border: none;
			font-size: 40px;
			margin-bottom: 0;
            @media screen and (max-width:768px){
                font-size: 26px;
				margin: 101px 30px 0 22px;
            }
			.border{
				position: relative;
				z-index: 3;
                @media screen and (max-width:768px){
                    float: left;
                }
				&::after{
                    content: "";
                    background: #fdea13;
                    width: 240px;
                    height: 16px;
                    position: absolute;
                    bottom: 5px;
                    right: 0px;
                    z-index: -1;
                    @media screen and (max-width:768px){
                        content: "";
                        background: #fdea13;
                        width: 154px;
                        height: 8px;
                        position: absolute;
                        bottom: 5px;
                        right: 0;
                        z-index: -1;
                    }
				}
			}
		}
		dd{
            border: none;
            font-size: 140px;
			margin-left: 140px;
            display: inline-block;
            color: #179c4f;
            font-weight: 900;
            font-family: "ＭＳ Ｐ明朝","MS PMincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro",serif;
            @media screen and (max-width:768px){
				font-size: 90px;
				margin-left: 0;
				img{
					width: 56%;
				}
            }
		}
	} 
	p{
        padding: 0px 26px;
        width: 66%;
        margin: 26px 0 0 2%;
        color: #fff;
        font-weight: 700!important;
        line-height: 1.9;
        @media screen and (max-width:768px){
        	width: 90%;
			margin: auto;
        }
		span{
			color: #f4fe5c;
		    font-size: 26px;
            @media screen and (max-width:768px){
				font-size: 20px;
            }
		}
        @media screen and (max-width:768px){
            margin: auto;
            width: 100%;
            font-size: 15px;
            padding: 10px;
        }
	}
	.flex{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	    width: 100%;
		height: auto;
		padding-bottom: 18px;
        @media screen and (max-width:768px){
            height: auto;
        }
		.flex-left{
            width: 100%; 
            display: flex;
            justify-content: center;
    		margin-top: 64px;
            @media screen and (max-width:768px){
    			width: 100%; 
				flex-wrap: wrap;
            }
			.flex-child{
                width: 21%;
			    height: auto;
                border-radius: 8px;
                margin: 1% 24px;
                background: rgba(255,255,255,.95);
                box-shadow: 0 0 18px 0 rgba(0,0,0,.08);
				@media screen and (max-width:768px){
                    width: 168px;
                    height: auto;
					margin: 0 2% 4% 2%;
				}
				dl{ 
					width: 100%; 
				    height: auto;
					margin: 0;
					border-radius: 8px;
					padding: 12px;
					dt{
                        background: #26a054; 
                        width: 90px; 
                        height: 90px; 
                        border-radius: 50%; 
                        margin: 14px auto 0;
					}
					dd{
                        font-size: 20px; 
                        margin: auto;
                        display: table;
                        margin-top: 24px; 
						color: #4F4F4F;
						padding: 0;
					    font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
                        @media screen and (max-width:768px){
                            font-size: 18px;
                        }
						&::before{
                            content: attr(data-eng);
                            font-size: 14px;
                            color: #179c4f;
                            display: block; 
                            position: absolute;
                            top: 118px;
                            left: 40.9%;
                            @media screen and (max-width:768px){
                                left: 62px;
                            }
						}
					} 
				}
				&:first-child{
					img{
                        width: 60%;
                        margin-left: 16px;
                        margin-top: 12px;
                        @media screen and (max-width:768px){
                            margin-top: 13px;
                            margin-top: 12px;
                        }
					}
				}
				&:nth-child(2){
					img{
                        width: 46%;
                        margin-left: 16px;
                        margin-top: 2px;
                        @media screen and (max-width:768px){
                            margin-left: 17px;
                            margin-top: 3px;
                        }
					}
				}
				&:nth-child(3){
					img{
                        width: 76%;
                        margin-left: 6px;
                        margin-top: 21px;
                        @media screen and (max-width:768px){
                            margin-left: 5px;
                            margin-top: 19px;
                            margin-top: 5px;
							margin-top: 18px;
                        }
					}					
				}
				&:nth-child(4){
					img{
                        width: 100%;
                        margin-left: -5px;
                        margin-top: 6px;
                        @media screen and (max-width:768px){
                            margin-left: -4px;
                            margin-top: 4px;
                        }
					}					
				}
			}
		}
		.flex-right{ 
            width: 100%;
			ul{
				display: flex;
				justify-content: center; 
				flex-wrap: wrap;
				padding: 0; 
				margin: 0 !important;
			    padding-top: 32px;
				width: 100%;
                @media screen and (max-width:768px){
                    padding-top: 0;
                }
				li{
					list-style: none; 
					width: 32%;
				    margin-bottom: 48px;
                    @media screen and (max-width:768px){
						width: 100%; 
                    }
					&:last-child{
						dl{
							&::before{
								display: none; 
							}
						}
						dd{
						}
					} 
                    dl{
						margin: 0 24px;
                        background: #fff;
                        box-shadow: 0 0 18px 0 rgba(0,0,0,.08);
						position: relative;
                        border-radius: 8px; 
						width: 90%;
						height: auto !important;
                        @media screen and (max-width:768px){
							margin: 19px;
                            height: auto;
                        }
						&::before{
							content: url(/images/index/first-next.png);
                            position: absolute;
                            top: 120px;
                            left: 350px;
                            z-index: 3; 
                            @media screen and (max-width:768px){
                                top: 93%;
                                left: 38%;
                                transform: rotate(91deg); 
                            }
						}  
                        dt{
                            padding: 0;
                            img{
                                margin: auto;
								border-radius: 8px 8px 0 0; 
                            }
                            @media screen and (max-width:768px){
                                margin: 0;
                            }
                        }
                        dd{
                            @include flex();
							align-items: center;
							justify-content: center;
							height: 100px;
 							font-size: 21px;
                            margin: auto;
                            padding: 0;
							text-align: center;
                            font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
							line-height: 1.5;
                            @media screen and (max-width:768px){
								font-size: 18px;
                                margin-bottom: 20px;
                                height: auto;
                            }
                        }
                    }
				}
			}

            }
		.flex-bottom{
			display: flex;
			flex-wrap: wrap;
			.text{ 
                background: #fff;
                width: 28%;
                border-radius: 8px;
                margin-right: 4%;
                padding: 16px 0 16px 0;
                @media screen and (max-width:768px){
                  width: 90%;
                  margin: auto;
                } 
				.child{ 
                    width: 90%;
                    margin: auto;
                    @media screen and (max-width:768px){
                       width: 90%;
					   margin: auto;
                    }
					figure{
						img{
					    	border-radius: 8px;
						}
					}
				}
				.child-second{
                  	width: 68%;
                    margin:　auto;
                    @media screen and (max-width:768px){
                       width: 90%;
                       margin-top: 0; 
                       margin-right: 0;
					   margin: auto;
                    }
					h4{ 
                        font-size: 26px;
                        font-weight: 700;
                        background: 0 0;
                        padding: 16px 0;
                        color: #179c4f;
                        text-align: center;
                        margin-bottom: auto;
                        padding-bottom: 0;
                        @media screen and (max-width:768px){
                        	font-size: 17px;
							margin-top: 16px;
                        } 
					}
                    p{  
                        margin: 0 !important;
                        color: #111; 
						padding: 0;
						font-size: 18px;
                        @media screen and (max-width:768px){
                           padding: 6px 2px!important;
					       font-size: 16px;
                        }
                    }
				}

			}
		}



        } 
	.width{
        width: 500px;
        border-radius: 8px;
        position: relative;
        height: 20px;
    	bottom: 116px;
        right: -24%;
        margin: auto;
        @media screen and (max-width:768px){
            width: 96%;
            border-radius: 8px;
            position: relative;
            height: 523px;
            right: auto;
            bottom: 27px;
            margin: auto;
        }
		h2{
            border-top: none!important;
            border-bottom: none!important;
            background-color: #a7ce59;
            border-radius: 5px 5px 0 0;
            color: #fff; 
            padding: 4px 10px 4px 14px;
            line-height: 1;
            font-size: 16px;
			margin-bottom: 0;
			&:before {
                width: 34px;
                height: 34px;
			} 
		}
		.news{
            margin: 0;
            padding: 0;
            overflow: auto;
            height: 120px; 
            text-decoration: none;
            background: #fff;
            border-radius: 0 0 5px 5px;
    		box-shadow: 2px 4px 17px -7px rgba(0,0,0,0.18);
			li{
                border-bottom: 1px dotted #111;
                list-style: none;
                padding-left: 42px;
                line-height: 1.4;
				a{
					font-size: 14px;
				}
				time{
					color: #d44950;
				    font-size: 12px;
				}
			}
		}
	}
    }
.INDEX{
	.display{
		max-width: 1300px;
		margin: auto;
	}
	&.first{
		padding: 64px;
        @media screen and (max-width:768px){
            padding: 32px;
        }
		h3{
			text-align: center;
            font-size: 30px;
            font-weight: bold;
			border-bottom: none;
		    box-shadow: none;
			padding: 0;
            @media screen and (max-width:768px){
                font-size: 19px;
            }
			&::after{
				display: none;
			}
		}
		.flex{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
            max-width: 980px;
            max-width: 1012px;
            padding: 32px;
            margin: auto;
			dl{
                width: 23%;
                height: 270px;
                border-radius: 4px;
                background: #fff;
                margin: 1%;
                @media screen and (max-width:768px){
                    width: 100%;
					margin: 3%;
                }
				dt{
					height: 130px;
					text-align: center;
					padding: 0;
					border: none;
				    margin-top: 24px;
					figure{
						line-height: 130px;
					}
				}
				dd{
					border: none;
				    padding: 0;
                    display: table;
                    margin: auto;
					font-weight: 700;
				    line-height: 1.7;
					span{
						color: #F1000F;
						font-size: 22px;
					}
				}
			}
		}
	}
	&.second{
		.bg{
			background: #F5FAE3;
			padding: 64px;
            @media screen and (max-width:768px){
				padding: 32px 0;
            }
            h3{						
                @media screen and (max-width:768px){
                    margin: 0;
                }
				img{
                    @media screen and (max-width:768px){
                        width: 80%;
                    }
				}
            }
            .flex{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                .flex-child{
                    width: 36%;
                    margin: 0 6%;
                    @media screen and (max-width:768px){
                        width: 80%;
                        margin: 30px 6%;
                    }
                    &:nth-child(2){
                      figure{
                              &::after {
                                  content: '完全に密着させるので\A違和感・異物感がなく痛みもない。';
                                  white-space: pre;
                                  font-size: 15px;
                                  color: #fff;
                                  position: absolute;
                                  bottom: 20px;
                                  right: -56px;
                                  width: 240px;
                                  font-weight: 500;
                              }
                              @media screen and (max-width:768px){
                                  &::after{
                                      bottom: -11px;
                                      right: -42px;
                                  }
                              }
                          }
                    } 
					figure{
						position: relative;
						img{
							width: 100%;
						}
                        &::before {
							content: url(/images/index/coment.png);
                            position: absolute;
                            bottom: -28px;
                            right: -90px;
                            @media screen and (max-width:768px){
                            	bottom: -56px;
                            }
                        }
						&::after{
							content: '歯に密着して義歯を支える。\A 複数の歯で支えるから負担も分散。';
							white-space: pre;
							font-size: 15px;
							color: #fff;
							position: absolute;
                            bottom: 18px;
                            right: -36px;
                            width: 225px;
                            font-weight: 500;
                            @media all and (-ms-high-contrast: none){
                              bottom: 0px;
                            }
                            @media screen and (max-width:768px){
                                 bottom: -11px;
							     right: -29px;
                            }
						}

					}
					dl{
						dt{
							font-size: 22px;
					 		border-bottom: 1px solid #179c4f;
							padding: 0;
							margin-top: 24px;
                            @media screen and (max-width:768px){
                                margin-top: 48px;
                            }
						}
						dd{
                            padding: 0;
                            font-weight: 500;
                            margin-top: 18px;
                            font-size: 18px;
                            text-align: justify;
                            line-height: 1.8em;
						}
					} 
                }
            }
        }
	}
	&.three{
    	padding: 64px;
        @media screen and (max-width:768px){
            padding: 32px;
        }
		h3{
          font-size: 32px; 
          font-weight: bold;
          @media screen and (max-width:768px){
              font-size: 26px;
          }
			.none{
				display: none;
                @media screen and (max-width:768px){
                    display: block;
                }
			}
		}
		.flex{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
		    max-width: 1012px;
			margin: 24px auto;
		    padding: 24px;
			border: 1px solid #00A549;
			position: relative;
			&::before{
				content: url(/images/index/point.png);
				position: absolute;
                top: -56px;
                right: 4px;
                @media screen and (max-width:768px){
                    display: none;
                }
			}
			&:nth-child(2){
                &::after{
                    content: url(/images/index/01.png);
                    position: absolute;
                    top: 12px;
                    left: 12px;
                }
			}
			&:nth-child(3){
                &::after{
                    content: url(/images/index/02.png);
                    position: absolute;
                    top: 12px;
                    left: 12px;
                }
			}
			
			.flex-child{
                width: 42%;
                margin-left: 2%;
                @media screen and (max-width:768px){
                    width: 100%;
                }
				h4{ 
					background: rgba(255,255,255,.0);
					color: #00A549;
				    font-size: 24px;
					font-weight: bold;
				}
				.flex-image{
					display: flex;
				}
			}
			.flex-childtwo{
                width: 48%;
                margin-left: 6%;
                margin-top: 6%;
                @media screen and (max-width:768px){
                    width: 100%;
					margin-left: 0;
                }
				p{
					font-size: 16px;
				}
				span{
					font-size: 15px;
					i{
						color: #00A549;
					}
				}
			}
			&:nth-child(4){
				border: none;
				padding: 0;
				&::before{
					display: none;
				}
				.threeColumn{
                    width: 32%;
                    margin: 0px 1%;
                    @media screen and (max-width:768px){
                        width: 100%;
						margin: 24px  0px;
                    }
					&:first-child{
						margin-left: 0;
						dl{
                            &::after{
                                content: url(/images/index/03.png);
                                position: absolute;
                                top: 12px;
                                left: 12px;
                            }
						}
					}
					&:nth-child(2){
						dl{
                            &::after{
                                content: url(/images/index/04.png);
                                position: absolute;
                                top: 12px;
                                left: 12px;
                            }
						}
					}
					&:last-child{
						margin-right: 0;
						dl{
                            &::after{
                                content: url(/images/index/05.png);
                                position: absolute;
                                top: 12px;
                                left: 12px;
                            }
						}
					}
					dl{
                        padding: 44px;
                        border: 1px solid #00a549;
                        height: 350px;
						position: relative;
                        @media screen and (max-width:768px){
                            height: auto;
                        }
						dt{
						    font-size: 20px;
							padding: 0;
							color: #00A549;
						}
						dd{
							padding: 0;
							margin-top: 32px;
							font-weight: 500;
                            text-align: justify;
                            line-height: 1.8em;
						}
					}
				}
			}
		}
		.btn{
            margin: auto;
            width: 100%;
			padding: 0;
    		box-shadow: none;
			a{
                text-decoration: none;
                font-size: 24px;
			    font-weight: 800;
			    padding-left: 28px;
                color: #fff;
				background: #009E01;
                width: 380px;
                height: 70px;
                line-height: 70px;
                display: inline-block;
                border-radius: 8px;
				position: relative;
			    box-shadow: 0px 4px 0 #007500;
			    transition: 0.2s all ease 0s;
                @media screen and (max-width:768px){
					font-size: 20px;
				    width: 310px;
                }
                &:hover{
                    box-shadow: none;
                    transform: translate3d(0, 3px, 0);
                }
				&::after{
                    content: url(/images/index/mail.png);
                    position: absolute;
                    top: 5px;
                    left: 24px;
				}
			}
		}
	}
	&.four{
		background: #F5F5F5;
	    padding: 64px;
        @media screen and (max-width:768px){
			padding: 32px;
        }
		.display{
			max-width: 1210px;
			margin: auto;
            h3{
                font-weight: 800;
                font-size: 30px;
            }
			h4{
				text-align: center;
			}
		.flex{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			.flex-child{
				margin: 1% 2%;
                @media screen and (max-width:768px){
                    dt{
                        iframe{
                            width: 100%;
                            height: 220px;
                        }
                    }
                }
				&:first-child{
					margin-left: 0;
					dl{
                        @media screen and (max-width:768px){
                            height: 440px;
                        }
						&::after{
                            content: "";
                            background: url(/images/index/bg05.png);
                            display: inline-block;
                            width: 100%;
                            height: 160px;
                            vertical-align: middle;
                            position: absolute;
                            bottom: 0px;
                            left: 0;
                            z-index: -1;
                            @media screen and (max-width:768px){
                                height: 220px;
                            }
						}
						dd{
                            color: #fff;
                            font-weight: bold;
                            font-size: 18px;
                            margin-top: 6px;
                            margin-left: 10px;
                            @media screen and (max-width:768px){
                                margin-left: 0;
                                margin-top: 12px;
                            }
							&:last-child{
                                color: #fff;
                                font-weight: bold;
                                margin-top: 22px;
							    font-size: 16px;
							}
						}
					}
				}
				&:nth-child(2){
					margin-left: 0;
					dl{
                        @media screen and (max-width:768px){
                            height: 440px;
                        }
						&::after{
                            content: "";
                            background: url(/images/index/bg05.png);
                            display: inline-block;
                            width: 100%;
                            height: 160px;
                            vertical-align: middle;
                            position: absolute;
                            bottom: 0px;
                            left: 0;
                            z-index: -1;
                            @media screen and (max-width:768px){
                                height: 238px;
                            }
						}
						dd{
                            color: #fff;
                            font-weight: bold;
                            font-size: 18px;
                            margin-top: 6px;
                            margin-left: 10px;
                            @media screen and (max-width:768px){
                                margin-left: 0;
                            }
							&:last-child{
                                color: #fff;
                                font-weight: bold;
                                margin-top: 22px;
							    font-size: 16px;
							}
						}
					}
				}
				&:nth-child(2){
					margin-right: 0;
				}
				&:nth-child(3){
					margin-left: 0;
				}
				&:nth-child(4){
					margin-right: 0;
                    @media screen and (max-width:768px){
                        dl{
                            dt{
                                figure{
                                    img{
                                        height: auto !important;
                                    }
                                }
                            }
                        }
                    }
				}
				h4{
					text-align: center;
					margin-bottom: 2px;
				}
				dl{
					border: 2px solid #00a549;
					padding: 28.7px;
					position: relative;
				    z-index: 2;
                    background: #fff;
                    @media screen and (max-width:768px){
                        height: 290px;
                    }
				}
				&:nth-child(4){
					h4{
						color: rgba(255,255,255,.0);
					}
				}
				position: relative;
				.sharp{
                    position: absolute;
                    bottom: 2px;
                    right: 2px;
                    z-index: 5;
                    background: #fff;
					width: 198px;
                    height: 45px;
					&:before{
                        content: "";
                        width: 0;
                        height: 0;
                        border-bottom: 45px solid #fff;
                        border-left: 21px solid transparent;
                        position: absolute;
                        right: 198px;
                        bottom: 0;
                    } 
					p{
                        line-height: 45px;
                        font-size: 15px;
                        padding-left: 22px;
                        margin: 0;
						color: #00A448;
						&::after{
                            content: url(/images/index/sharp.png);
                            position: absolute;
                            bottom: 1px;
                            right: 20px;
						}
					}
				}
			}
		}
		.flex-width{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 93%;
            border: 2px solid #00a549;
            margin: auto;
			h4{
				text-align: center;
			}
			dl{
    			margin: 2% 3%;
                @media screen and (max-width:768px){
                    margin: 8% 3%;
                }
				dd{
					text-align: center;
					font-size: 20px;
				    margin-top: 12px;
					font-weight: 600;
                    }
           @media screen and (max-width:768px){
                        dt{
                            iframe{
                                width: 100%;
                                height: 220px;
                            }
                        }
				}
			}
		}
	}
}
	&.five{
		padding: 64px;
        @media screen and (max-width:768px){
            padding: 32px;
        }
		.display{
			max-width: 1210px;
			margin: auto;
            display: flex;
            justify-content: center;
			flex-wrap: wrap;
            .btn{
                width: 39%;
				padding: 0;
				box-shadow: none;
                @media screen and (max-width:768px){
                    width: 100%;
				    margin: 14px;
                }
                a{
                    text-decoration: none;
                    font-size: 24px;
                    font-weight: 800;
                    color: #fff;
                    background: #009E01;
                    width: 400px;
                    height: 70px;
                    line-height: 70px;
					padding-right: 20px;
                    display: inline-block;
                    border-radius: 8px;
                    position: relative;
                    box-shadow: 0px 4px 0 #007500;
                    transition: 0.2s all ease 0s;
                    @media screen and (max-width:768px){
                         width: 290px;
                    }
                    &:hover{
                        box-shadow: none;
                        transform: translate3d(0, 3px, 0);
                    }
                    &::after{
                        content: url(/images/index/next.png);
                        position: absolute;
                        top: 5px;
                        right: 34px;
                    }
                }
            }
		}
	}
	&.six{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.item{
			background: #C6E5C3;
			width: 50%;
		    padding: 48px 0px 48px 93px;
			position: relative;
            @media screen and (max-width:768px){
                width: 100%;
				padding: 24px;
            }
			&:first-child{
				&::after{
					content: "";
					position: absolute;
					top: 35%;
					font-size: 18px;
					font-weight: bold;
					color: #31682E;
                    @media screen and (max-width:768px){
    					top: 23%;
                    }
				}
			}
			&:last-child{
				background: #456E42;
                @media screen and (max-width:768px){
    				padding: 60px 30px !important;
                }
			}
            figure{
                width: 245px;
                margin: 4px 0 0;
            }
			.flex{
				display: flex;
				margin-top: 74px;
                @media screen and (max-width:768px){
                    flex-wrap: wrap;
				    margin-top: 18px;
                }
				dl{
                    @media screen and (max-width:768px){
                        margin-top: 26px;
                    }
					dt{
                        background: #2F692C;
                        display: inline-block;
                        padding: 4px 16px;
                        color: #fff;
                        border-radius: 16px;
                        font-weight: 500;
                        font-size: 16px;
					}
                    dd{
                        margin-top: 12px;
                        font-size: 16px;
                        font-weight: 500;
                    }
					&:last-child{
						margin-left: 6%;
                        @media screen and (max-width:768px){
							margin: 26px 0 0 0 !important;
                        }
					}
				}
			}
			&:last-child{
		    	padding: 76px 0 46px 160px;
                .right{ 
                    dl{
                        dt{
                            font-size: 44px;
							padding-left: 60px;
                            color: #F5D600;
                            position: relative;
                            @media screen and (max-width:768px){
                                    font-size: 38px;
                            }
							&::after{
								content: url(/images/index/tel.png);
								position: absolute;
						    	top: 3px;
    							left: 0;	
							}
                            &::before{
                                content: attr(data-eng);
                                display: block;
                                color: #fff;
                                font-size: 16px;
                                position: absolute;
                                top: -20px;
                                left: 64px;
                            }
                        }
                        dd{
                            display: inline-block;
                            margin: 0px 0px 20px 65px;
                            color: #fff;
						    font-size: 14px;
                        }
                    }
					p{
                        font-size: 14px;
                        color: #fff;
					    margin-left: 16px;
    					margin-top: 14px;
					}
					.btn{
						padding: 0;
                          a{
                              text-decoration: none;
                              font-size: 16px;
                              font-weight: 800;
                              color: #5E8036;
                              background: #fff;
                              width: 320px;
                              height: 50px;
                              line-height: 50px;
                              padding-right: 20px;
                              padding-left: 60px;
                              display: inline-block;
                              border-radius: 8px;
                              position: relative;
                              &:hover{
                              }
                              &::after{
                                  content: url(/images/index/mail-2.png);
                                  position: absolute;
                                  top: 5px;
                                  left: 20px;
                              }
                          }
					}
                }
			}
		}
	}
	dl,dt,dd{ 
		border: none;
		padding: 0;
		margin: 0;
	}
	h3{
		border: none;
		text-align: center;
		box-shadow: none;
		&::after{
			border: none;
		}
	}
    h4{ 
        background: rgba(255,255,255,.0);
        font-size: 24px;
        font-weight: bold;
    }
}
