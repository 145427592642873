@charset "UTF-8";
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/*
html{
	overflow: hidden;
}
*/
@media (min-width: 992px) {
  .container {
    width: 1000px !important; } }

.page-header {
  margin-top: 84px; }
  @media screen and (max-width: 768px) {
    .page-header {
      margin-top: 112px; } }

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 10px; }
  @media screen and (max-width: 768px) {
    .navbar {
      height: 110px; } }

@media screen and (min-width: 769px) {
  .CUSTUM-HEADER {
    box-shadow: none;
    height: 100px; }
    .CUSTUM-HEADER h1 {
      width: 180px;
      margin: 16px 0 0; }
      .CUSTUM-HEADER h1 img {
        width: 100%; }
      .CUSTUM-HEADER h1 + div {
        display: none; }
    .CUSTUM-HEADER .siteHeadContainer {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
    .CUSTUM-HEADER .gMenu {
      margin: 14px 0 0; }
      .CUSTUM-HEADER .gMenu > li > a {
        padding: 10px 12px;
        font-size: 12px; }
      .CUSTUM-HEADER .gMenu > li {
        line-height: 4; }
        .CUSTUM-HEADER .gMenu > li:last-child {
          width: 120px;
          height: 80px;
          background: #5f8c00; }
          .CUSTUM-HEADER .gMenu > li:last-child a,
          .CUSTUM-HEADER .gMenu > li:last-child a:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            text-indent: -9999px; }
          .CUSTUM-HEADER .gMenu > li:last-child a:before {
            content: '';
            z-index: 10;
            background: url(/images/common/contact_bg.jpg); }
          .CUSTUM-HEADER .gMenu > li:last-child:after {
            display: none; } }

@media screen and (max-width: 768px) {
  .CUSTUM-HEADER h1 {
    width: 200px;
    margin: 4px 0; }
    .CUSTUM-HEADER h1 img {
      width: 100%; }
    .CUSTUM-HEADER h1 + div {
      display: none; } }

.vk-mobile-nav-menu-btn {
  display: none; }

@media screen and (max-width: 992px) {
  .vk-mobile-nav-menu-btn {
    display: block;
    top: 15px;
    right: 15px;
    left: auto; } }

a#menuBtn {
  display: none; }

/*
#HEADER {
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	height: 80px;
	background: rgba(255,255,255,.96);
	padding: 0 30px;
	z-index: 100;
	@media screen and (max-width:768px){
		height: 60px;
		padding: 0;
	}
	a {
		color: #111;
	}
	.logo {
		position: absolute;
		top: 16px;
		left: 60px;
		width: 250px;
		@media screen and (max-width:768px){
			top: 8px; 
			left: 12px;
		}
		img {
			max-width: 240px;
			height: auto;
			@media screen and (max-width:768px){
				max-width: 73px;
			}
		}
		span {
			display: inline-block;
			margin-left: 12px;
			font-weight: 700;
		}
	}
	
	.gnavi {
            float: right;
		@media screen and (max-width:768px){
			display: block;
			width: 32px;
			height: 32px;
			background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;

			margin:14px 12px 0 0;
			cursor:pointer;
			&.addClose {
				background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;

				ul {
					border-top: 1px solid #eee;
				}
			}
		}
		ul {
			margin: 0 !important;
			height: 80px;
			@media screen and (max-width:768px){
				display: none;
				position: absolute;
				top: 60px;
				left: 0;
				width: 100%;
			}
			>li {
				float: left;
				width: 154px;
				list-style: none;
				line-height: 80px;
				@media screen and (max-width:768px){
					float: none;
					width: 100% !important;
					background: rgba(255,255,255,.96);
					text-align: left;
					border-bottom: 1px solid #d5d5d5;
				}
				&:last-child{
					width: 100px;
					background: $darkgreen;
					a{
						color: #fff;
                        margin-top: 18px;
                        height: 62px;
						position: relative;
                        &::before {
                            font-family: "Font Awesome 5 Free";
                            content: '\f0e0';
                            font-weight: 900;
                            display: block;
                            text-align: center;
                            font-size: 24px; 
                            color: #FFFFFF;
                            position: absolute;
                            top: -28px;
                            left: 37px;
                        }
					}
				}
				a,.link {
					display: block;
					text-align: center;
					font-size: 14px;
					font-weight: 700;
					position: relative;
					cursor: pointer;
					@media screen and (max-width:768px){
						font-size: 14px;
						padding: 12px 0;
					}

					&:hover {
						&:after {
						  margin-left:-50px;
						  width:100px;
						}
					}
					
					
				}
				&.acd {
					position: relative;
					.trigger{
						cursor:pointer;
						@media screen and (max-width:768px){
							background-image: url("../../images/common/icon_plus_02.png");
							background-position: right 12px center;
							background-repeat: no-repeat;
						}
					}			

                }
            }
        }
    }
}

*/
footer {
  border: 0 !important; }

#FOOTER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/ }
  @media screen and (min-width: 769px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 0 0 26px 0;
      background: #253F22;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 0 0 0 0; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 0;
          display: inline-block;
          margin-left: 10px; }
          #FOOTER .fnav li:after {
            content: "|";
            display: inline-block;
            margin-left: 10px;
            color: rgba(255, 255, 255, 0.32); }
          #FOOTER .fnav li:last-child:after {
            display: none; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
      #FOOTER .copy {
        display: block;
        font-size: 14px;
        color: #FFF;
        padding: 0 0 0 0;
        text-align: center;
        margin: 31px 0; } }
  @media screen and (max-width: 768px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 0 0 0 0;
      background: #253F22;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 0 0 0 0;
        border: #DDD 1px solid;
        border-radius: 4px;
        overflow: hidden; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 15px;
          display: inline-block;
          margin-left: 15px; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
        #FOOTER .fnav li {
          float: none;
          text-align: left;
          display: block;
          margin: 0;
          padding: 0;
          border-bottom: #DDD 1px solid; }
          #FOOTER .fnav li:last-child {
            border: none; }
          #FOOTER .fnav li a {
            background: #FFF;
            color: #333;
            display: block;
            padding: 15px 15px; }
            #FOOTER .fnav li a:hover {
              opacity: .9; }
      #FOOTER .copy {
        display: block;
        font-size: 14px;
        color: #FFF;
        padding: 20px 0 0 0;
        text-align: center;
        margin: 31px 0; } }
  #FOOTER a {
    color: #FFF; }
    #FOOTER a:hover {
      opacity: .8; }
  @media screen and (max-width: 768px) {
    #FOOTER .display {
      margin: 0 15px; }
    #FOOTER .fnav {
      border: none; }
      #FOOTER .fnav li a {
        background: #FFF url(../../../images/common/arrow_icon_2x.png) no-repeat right 15px center;
        background-size: 5px; }
        #FOOTER .fnav li a:hover {
          background: #EEE url(../../../images/common/arrow_icon_2x.png) no-repeat right 15px center;
          background-size: 5px; } }

#WRAPPER {
  max-width: 1920px;
  margin: auto; }

#firstView {
  background: url(/images/index/firstview07.jpg) no-repeat;
  background-position: initial;
  background-size: inherit;
  margin-top: 62px; }
  #firstView .display {
    max-width: 1300px; }
  @media screen and (max-width: 768px) {
    #firstView .mask {
      background: rgba(255, 255, 255, 0.8); } }
  #firstView dl {
    float: right;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
    height: 330px; }
    @media screen and (max-width: 768px) {
      #firstView dl {
        height: 380px;
        margin: 0; } }
    #firstView dl dt {
      border: none;
      font-size: 40px;
      margin-bottom: 0; }
      @media screen and (max-width: 768px) {
        #firstView dl dt {
          font-size: 26px;
          margin: 101px 30px 0 22px; } }
      #firstView dl dt .border {
        position: relative;
        z-index: 3; }
        @media screen and (max-width: 768px) {
          #firstView dl dt .border {
            float: left; } }
        #firstView dl dt .border::after {
          content: "";
          background: #fdea13;
          width: 240px;
          height: 16px;
          position: absolute;
          bottom: 5px;
          right: 0px;
          z-index: -1; }
          @media screen and (max-width: 768px) {
            #firstView dl dt .border::after {
              content: "";
              background: #fdea13;
              width: 154px;
              height: 8px;
              position: absolute;
              bottom: 5px;
              right: 0;
              z-index: -1; } }
    #firstView dl dd {
      border: none;
      font-size: 140px;
      margin-left: 140px;
      display: inline-block;
      color: #179c4f;
      font-weight: 900;
      font-family: "ＭＳ Ｐ明朝","MS PMincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro",serif; }
      @media screen and (max-width: 768px) {
        #firstView dl dd {
          font-size: 90px;
          margin-left: 0; }
          #firstView dl dd img {
            width: 56%; } }
  #firstView p {
    padding: 0px 26px;
    width: 66%;
    margin: 26px 0 0 2%;
    color: #fff;
    font-weight: 700 !important;
    line-height: 1.9; }
    @media screen and (max-width: 768px) {
      #firstView p {
        width: 90%;
        margin: auto; } }
    #firstView p span {
      color: #f4fe5c;
      font-size: 26px; }
      @media screen and (max-width: 768px) {
        #firstView p span {
          font-size: 20px; } }
    @media screen and (max-width: 768px) {
      #firstView p {
        margin: auto;
        width: 100%;
        font-size: 15px;
        padding: 10px; } }
  #firstView .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-bottom: 18px; }
    @media screen and (max-width: 768px) {
      #firstView .flex {
        height: auto; } }
    #firstView .flex .flex-left {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 64px; }
      @media screen and (max-width: 768px) {
        #firstView .flex .flex-left {
          width: 100%;
          flex-wrap: wrap; } }
      #firstView .flex .flex-left .flex-child {
        width: 21%;
        height: auto;
        border-radius: 8px;
        margin: 1% 24px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08); }
        @media screen and (max-width: 768px) {
          #firstView .flex .flex-left .flex-child {
            width: 168px;
            height: auto;
            margin: 0 2% 4% 2%; } }
        #firstView .flex .flex-left .flex-child dl {
          width: 100%;
          height: auto;
          margin: 0;
          border-radius: 8px;
          padding: 12px; }
          #firstView .flex .flex-left .flex-child dl dt {
            background: #26a054;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin: 14px auto 0; }
          #firstView .flex .flex-left .flex-child dl dd {
            font-size: 20px;
            margin: auto;
            display: table;
            margin-top: 24px;
            color: #4F4F4F;
            padding: 0;
            font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif !important; }
            @media screen and (max-width: 768px) {
              #firstView .flex .flex-left .flex-child dl dd {
                font-size: 18px; } }
            #firstView .flex .flex-left .flex-child dl dd::before {
              content: attr(data-eng);
              font-size: 14px;
              color: #179c4f;
              display: block;
              position: absolute;
              top: 118px;
              left: 40.9%; }
              @media screen and (max-width: 768px) {
                #firstView .flex .flex-left .flex-child dl dd::before {
                  left: 62px; } }
        #firstView .flex .flex-left .flex-child:first-child img {
          width: 60%;
          margin-left: 16px;
          margin-top: 12px; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-left .flex-child:first-child img {
              margin-top: 13px;
              margin-top: 12px; } }
        #firstView .flex .flex-left .flex-child:nth-child(2) img {
          width: 46%;
          margin-left: 16px;
          margin-top: 2px; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-left .flex-child:nth-child(2) img {
              margin-left: 17px;
              margin-top: 3px; } }
        #firstView .flex .flex-left .flex-child:nth-child(3) img {
          width: 76%;
          margin-left: 6px;
          margin-top: 21px; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-left .flex-child:nth-child(3) img {
              margin-left: 5px;
              margin-top: 19px;
              margin-top: 5px;
              margin-top: 18px; } }
        #firstView .flex .flex-left .flex-child:nth-child(4) img {
          width: 100%;
          margin-left: -5px;
          margin-top: 6px; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-left .flex-child:nth-child(4) img {
              margin-left: -4px;
              margin-top: 4px; } }
    #firstView .flex .flex-right {
      width: 100%; }
      #firstView .flex .flex-right ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 !important;
        padding-top: 32px;
        width: 100%; }
        @media screen and (max-width: 768px) {
          #firstView .flex .flex-right ul {
            padding-top: 0; } }
        #firstView .flex .flex-right ul li {
          list-style: none;
          width: 32%;
          margin-bottom: 48px; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-right ul li {
              width: 100%; } }
          #firstView .flex .flex-right ul li:last-child dl::before {
            display: none; }
          #firstView .flex .flex-right ul li dl {
            margin: 0 24px;
            background: #fff;
            box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
            position: relative;
            border-radius: 8px;
            width: 90%;
            height: auto !important; }
            @media screen and (max-width: 768px) {
              #firstView .flex .flex-right ul li dl {
                margin: 19px;
                height: auto; } }
            #firstView .flex .flex-right ul li dl::before {
              content: url(/images/index/first-next.png);
              position: absolute;
              top: 120px;
              left: 350px;
              z-index: 3; }
              @media screen and (max-width: 768px) {
                #firstView .flex .flex-right ul li dl::before {
                  top: 93%;
                  left: 38%;
                  transform: rotate(91deg); } }
            #firstView .flex .flex-right ul li dl dt {
              padding: 0; }
              #firstView .flex .flex-right ul li dl dt img {
                margin: auto;
                border-radius: 8px 8px 0 0; }
              @media screen and (max-width: 768px) {
                #firstView .flex .flex-right ul li dl dt {
                  margin: 0; } }
            #firstView .flex .flex-right ul li dl dd {
              display: -webkit-flex;
              display: -moz-flex;
              display: -o-flex;
              display: -ms-flex;
              display: flex;
              -moz-flex-flow: row wrap;
              -webkit-flex-flow: row wrap;
              -o-flex-flow: row wrap;
              -ms-flex-flow: row wrap;
              flex-flow: row wrap;
              -webkit-justify-content: space-between;
              -o-justify-content: space-between;
              -ms-justify-content: space-between;
              -moz-justify-content: space-between;
              justify-content: space-between;
              align-items: center;
              justify-content: center;
              height: 100px;
              font-size: 21px;
              margin: auto;
              padding: 0;
              text-align: center;
              font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
              line-height: 1.5; }
              @media screen and (max-width: 768px) {
                #firstView .flex .flex-right ul li dl dd {
                  font-size: 18px;
                  margin-bottom: 20px;
                  height: auto; } }
    #firstView .flex .flex-bottom {
      display: flex;
      flex-wrap: wrap; }
      #firstView .flex .flex-bottom .text {
        background: #fff;
        width: 28%;
        border-radius: 8px;
        margin-right: 4%;
        padding: 16px 0 16px 0; }
        @media screen and (max-width: 768px) {
          #firstView .flex .flex-bottom .text {
            width: 90%;
            margin: auto; } }
        #firstView .flex .flex-bottom .text .child {
          width: 90%;
          margin: auto; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-bottom .text .child {
              width: 90%;
              margin: auto; } }
          #firstView .flex .flex-bottom .text .child figure img {
            border-radius: 8px; }
        #firstView .flex .flex-bottom .text .child-second {
          width: 68%;
          margin: 　auto; }
          @media screen and (max-width: 768px) {
            #firstView .flex .flex-bottom .text .child-second {
              width: 90%;
              margin-top: 0;
              margin-right: 0;
              margin: auto; } }
          #firstView .flex .flex-bottom .text .child-second h4 {
            font-size: 26px;
            font-weight: 700;
            background: 0 0;
            padding: 16px 0;
            color: #179c4f;
            text-align: center;
            margin-bottom: auto;
            padding-bottom: 0; }
            @media screen and (max-width: 768px) {
              #firstView .flex .flex-bottom .text .child-second h4 {
                font-size: 17px;
                margin-top: 16px; } }
          #firstView .flex .flex-bottom .text .child-second p {
            margin: 0 !important;
            color: #111;
            padding: 0;
            font-size: 18px; }
            @media screen and (max-width: 768px) {
              #firstView .flex .flex-bottom .text .child-second p {
                padding: 6px 2px !important;
                font-size: 16px; } }
  #firstView .width {
    width: 500px;
    border-radius: 8px;
    position: relative;
    height: 20px;
    bottom: 116px;
    right: -24%;
    margin: auto; }
    @media screen and (max-width: 768px) {
      #firstView .width {
        width: 96%;
        border-radius: 8px;
        position: relative;
        height: 523px;
        right: auto;
        bottom: 27px;
        margin: auto; } }
    #firstView .width h2 {
      border-top: none !important;
      border-bottom: none !important;
      background-color: #a7ce59;
      border-radius: 5px 5px 0 0;
      color: #fff;
      padding: 4px 10px 4px 14px;
      line-height: 1;
      font-size: 16px;
      margin-bottom: 0; }
      #firstView .width h2:before {
        width: 34px;
        height: 34px; }
    #firstView .width .news {
      margin: 0;
      padding: 0;
      overflow: auto;
      height: 120px;
      text-decoration: none;
      background: #fff;
      border-radius: 0 0 5px 5px;
      box-shadow: 2px 4px 17px -7px rgba(0, 0, 0, 0.18); }
      #firstView .width .news li {
        border-bottom: 1px dotted #111;
        list-style: none;
        padding-left: 42px;
        line-height: 1.4; }
        #firstView .width .news li a {
          font-size: 14px; }
        #firstView .width .news li time {
          color: #d44950;
          font-size: 12px; }

.INDEX .display {
  max-width: 1300px;
  margin: auto; }

.INDEX.first {
  padding: 64px; }
  @media screen and (max-width: 768px) {
    .INDEX.first {
      padding: 32px; } }
  .INDEX.first h3 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    border-bottom: none;
    box-shadow: none;
    padding: 0; }
    @media screen and (max-width: 768px) {
      .INDEX.first h3 {
        font-size: 19px; } }
    .INDEX.first h3::after {
      display: none; }
  .INDEX.first .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 980px;
    max-width: 1012px;
    padding: 32px;
    margin: auto; }
    .INDEX.first .flex dl {
      width: 23%;
      height: 270px;
      border-radius: 4px;
      background: #fff;
      margin: 1%; }
      @media screen and (max-width: 768px) {
        .INDEX.first .flex dl {
          width: 100%;
          margin: 3%; } }
      .INDEX.first .flex dl dt {
        height: 130px;
        text-align: center;
        padding: 0;
        border: none;
        margin-top: 24px; }
        .INDEX.first .flex dl dt figure {
          line-height: 130px; }
      .INDEX.first .flex dl dd {
        border: none;
        padding: 0;
        display: table;
        margin: auto;
        font-weight: 700;
        line-height: 1.7; }
        .INDEX.first .flex dl dd span {
          color: #F1000F;
          font-size: 22px; }

.INDEX.second .bg {
  background: #F5FAE3;
  padding: 64px; }
  @media screen and (max-width: 768px) {
    .INDEX.second .bg {
      padding: 32px 0; } }
  @media screen and (max-width: 768px) {
    .INDEX.second .bg h3 {
      margin: 0; } }
  @media screen and (max-width: 768px) {
    .INDEX.second .bg h3 img {
      width: 80%; } }
  .INDEX.second .bg .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .INDEX.second .bg .flex .flex-child {
      width: 36%;
      margin: 0 6%; }
      @media screen and (max-width: 768px) {
        .INDEX.second .bg .flex .flex-child {
          width: 80%;
          margin: 30px 6%; } }
      .INDEX.second .bg .flex .flex-child:nth-child(2) figure::after {
        content: '完全に密着させるので\A違和感・異物感がなく痛みもない。';
        white-space: pre;
        font-size: 15px;
        color: #fff;
        position: absolute;
        bottom: 20px;
        right: -56px;
        width: 240px;
        font-weight: 500; }
      @media screen and (max-width: 768px) {
        .INDEX.second .bg .flex .flex-child:nth-child(2) figure::after {
          bottom: -11px;
          right: -42px; } }
      .INDEX.second .bg .flex .flex-child figure {
        position: relative; }
        .INDEX.second .bg .flex .flex-child figure img {
          width: 100%; }
        .INDEX.second .bg .flex .flex-child figure::before {
          content: url(/images/index/coment.png);
          position: absolute;
          bottom: -28px;
          right: -90px; }
          @media screen and (max-width: 768px) {
            .INDEX.second .bg .flex .flex-child figure::before {
              bottom: -56px; } }
        .INDEX.second .bg .flex .flex-child figure::after {
          content: '歯に密着して義歯を支える。\A 複数の歯で支えるから負担も分散。';
          white-space: pre;
          font-size: 15px;
          color: #fff;
          position: absolute;
          bottom: 18px;
          right: -36px;
          width: 225px;
          font-weight: 500; }
          @media all and (-ms-high-contrast: none) {
            .INDEX.second .bg .flex .flex-child figure::after {
              bottom: 0px; } }
          @media screen and (max-width: 768px) {
            .INDEX.second .bg .flex .flex-child figure::after {
              bottom: -11px;
              right: -29px; } }
      .INDEX.second .bg .flex .flex-child dl dt {
        font-size: 22px;
        border-bottom: 1px solid #179c4f;
        padding: 0;
        margin-top: 24px; }
        @media screen and (max-width: 768px) {
          .INDEX.second .bg .flex .flex-child dl dt {
            margin-top: 48px; } }
      .INDEX.second .bg .flex .flex-child dl dd {
        padding: 0;
        font-weight: 500;
        margin-top: 18px;
        font-size: 18px;
        text-align: justify;
        line-height: 1.8em; }

.INDEX.three {
  padding: 64px; }
  @media screen and (max-width: 768px) {
    .INDEX.three {
      padding: 32px; } }
  .INDEX.three h3 {
    font-size: 32px;
    font-weight: bold; }
    @media screen and (max-width: 768px) {
      .INDEX.three h3 {
        font-size: 26px; } }
    .INDEX.three h3 .none {
      display: none; }
      @media screen and (max-width: 768px) {
        .INDEX.three h3 .none {
          display: block; } }
  .INDEX.three .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1012px;
    margin: 24px auto;
    padding: 24px;
    border: 1px solid #00A549;
    position: relative; }
    .INDEX.three .flex::before {
      content: url(/images/index/point.png);
      position: absolute;
      top: -56px;
      right: 4px; }
      @media screen and (max-width: 768px) {
        .INDEX.three .flex::before {
          display: none; } }
    .INDEX.three .flex:nth-child(2)::after {
      content: url(/images/index/01.png);
      position: absolute;
      top: 12px;
      left: 12px; }
    .INDEX.three .flex:nth-child(3)::after {
      content: url(/images/index/02.png);
      position: absolute;
      top: 12px;
      left: 12px; }
    .INDEX.three .flex .flex-child {
      width: 42%;
      margin-left: 2%; }
      @media screen and (max-width: 768px) {
        .INDEX.three .flex .flex-child {
          width: 100%; } }
      .INDEX.three .flex .flex-child h4 {
        background: rgba(255, 255, 255, 0);
        color: #00A549;
        font-size: 24px;
        font-weight: bold; }
      .INDEX.three .flex .flex-child .flex-image {
        display: flex; }
    .INDEX.three .flex .flex-childtwo {
      width: 48%;
      margin-left: 6%;
      margin-top: 6%; }
      @media screen and (max-width: 768px) {
        .INDEX.three .flex .flex-childtwo {
          width: 100%;
          margin-left: 0; } }
      .INDEX.three .flex .flex-childtwo p {
        font-size: 16px; }
      .INDEX.three .flex .flex-childtwo span {
        font-size: 15px; }
        .INDEX.three .flex .flex-childtwo span i {
          color: #00A549; }
    .INDEX.three .flex:nth-child(4) {
      border: none;
      padding: 0; }
      .INDEX.three .flex:nth-child(4)::before {
        display: none; }
      .INDEX.three .flex:nth-child(4) .threeColumn {
        width: 32%;
        margin: 0px 1%; }
        @media screen and (max-width: 768px) {
          .INDEX.three .flex:nth-child(4) .threeColumn {
            width: 100%;
            margin: 24px  0px; } }
        .INDEX.three .flex:nth-child(4) .threeColumn:first-child {
          margin-left: 0; }
          .INDEX.three .flex:nth-child(4) .threeColumn:first-child dl::after {
            content: url(/images/index/03.png);
            position: absolute;
            top: 12px;
            left: 12px; }
        .INDEX.three .flex:nth-child(4) .threeColumn:nth-child(2) dl::after {
          content: url(/images/index/04.png);
          position: absolute;
          top: 12px;
          left: 12px; }
        .INDEX.three .flex:nth-child(4) .threeColumn:last-child {
          margin-right: 0; }
          .INDEX.three .flex:nth-child(4) .threeColumn:last-child dl::after {
            content: url(/images/index/05.png);
            position: absolute;
            top: 12px;
            left: 12px; }
        .INDEX.three .flex:nth-child(4) .threeColumn dl {
          padding: 44px;
          border: 1px solid #00a549;
          height: 350px;
          position: relative; }
          @media screen and (max-width: 768px) {
            .INDEX.three .flex:nth-child(4) .threeColumn dl {
              height: auto; } }
          .INDEX.three .flex:nth-child(4) .threeColumn dl dt {
            font-size: 20px;
            padding: 0;
            color: #00A549; }
          .INDEX.three .flex:nth-child(4) .threeColumn dl dd {
            padding: 0;
            margin-top: 32px;
            font-weight: 500;
            text-align: justify;
            line-height: 1.8em; }
  .INDEX.three .btn {
    margin: auto;
    width: 100%;
    padding: 0;
    box-shadow: none; }
    .INDEX.three .btn a {
      text-decoration: none;
      font-size: 24px;
      font-weight: 800;
      padding-left: 28px;
      color: #fff;
      background: #009E01;
      width: 380px;
      height: 70px;
      line-height: 70px;
      display: inline-block;
      border-radius: 8px;
      position: relative;
      box-shadow: 0px 4px 0 #007500;
      transition: 0.2s all ease 0s; }
      @media screen and (max-width: 768px) {
        .INDEX.three .btn a {
          font-size: 20px;
          width: 310px; } }
      .INDEX.three .btn a:hover {
        box-shadow: none;
        transform: translate3d(0, 3px, 0); }
      .INDEX.three .btn a::after {
        content: url(/images/index/mail.png);
        position: absolute;
        top: 5px;
        left: 24px; }

.INDEX.four {
  background: #F5F5F5;
  padding: 64px; }
  @media screen and (max-width: 768px) {
    .INDEX.four {
      padding: 32px; } }
  .INDEX.four .display {
    max-width: 1210px;
    margin: auto; }
    .INDEX.four .display h3 {
      font-weight: 800;
      font-size: 30px; }
    .INDEX.four .display h4 {
      text-align: center; }
    .INDEX.four .display .flex {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .INDEX.four .display .flex .flex-child {
        margin: 1% 2%;
        position: relative; }
        @media screen and (max-width: 768px) {
          .INDEX.four .display .flex .flex-child dt iframe {
            width: 100%;
            height: 220px; } }
        .INDEX.four .display .flex .flex-child:first-child {
          margin-left: 0; }
          @media screen and (max-width: 768px) {
            .INDEX.four .display .flex .flex-child:first-child dl {
              height: 440px; } }
          .INDEX.four .display .flex .flex-child:first-child dl::after {
            content: "";
            background: url(/images/index/bg05.png);
            display: inline-block;
            width: 100%;
            height: 160px;
            vertical-align: middle;
            position: absolute;
            bottom: 0px;
            left: 0;
            z-index: -1; }
            @media screen and (max-width: 768px) {
              .INDEX.four .display .flex .flex-child:first-child dl::after {
                height: 220px; } }
          .INDEX.four .display .flex .flex-child:first-child dl dd {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            margin-top: 6px;
            margin-left: 10px; }
            @media screen and (max-width: 768px) {
              .INDEX.four .display .flex .flex-child:first-child dl dd {
                margin-left: 0;
                margin-top: 12px; } }
            .INDEX.four .display .flex .flex-child:first-child dl dd:last-child {
              color: #fff;
              font-weight: bold;
              margin-top: 22px;
              font-size: 16px; }
        .INDEX.four .display .flex .flex-child:nth-child(2) {
          margin-left: 0; }
          @media screen and (max-width: 768px) {
            .INDEX.four .display .flex .flex-child:nth-child(2) dl {
              height: 440px; } }
          .INDEX.four .display .flex .flex-child:nth-child(2) dl::after {
            content: "";
            background: url(/images/index/bg05.png);
            display: inline-block;
            width: 100%;
            height: 160px;
            vertical-align: middle;
            position: absolute;
            bottom: 0px;
            left: 0;
            z-index: -1; }
            @media screen and (max-width: 768px) {
              .INDEX.four .display .flex .flex-child:nth-child(2) dl::after {
                height: 238px; } }
          .INDEX.four .display .flex .flex-child:nth-child(2) dl dd {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
            margin-top: 6px;
            margin-left: 10px; }
            @media screen and (max-width: 768px) {
              .INDEX.four .display .flex .flex-child:nth-child(2) dl dd {
                margin-left: 0; } }
            .INDEX.four .display .flex .flex-child:nth-child(2) dl dd:last-child {
              color: #fff;
              font-weight: bold;
              margin-top: 22px;
              font-size: 16px; }
        .INDEX.four .display .flex .flex-child:nth-child(2) {
          margin-right: 0; }
        .INDEX.four .display .flex .flex-child:nth-child(3) {
          margin-left: 0; }
        .INDEX.four .display .flex .flex-child:nth-child(4) {
          margin-right: 0; }
          @media screen and (max-width: 768px) {
            .INDEX.four .display .flex .flex-child:nth-child(4) dl dt figure img {
              height: auto !important; } }
        .INDEX.four .display .flex .flex-child h4 {
          text-align: center;
          margin-bottom: 2px; }
        .INDEX.four .display .flex .flex-child dl {
          border: 2px solid #00a549;
          padding: 28.7px;
          position: relative;
          z-index: 2;
          background: #fff; }
          @media screen and (max-width: 768px) {
            .INDEX.four .display .flex .flex-child dl {
              height: 290px; } }
        .INDEX.four .display .flex .flex-child:nth-child(4) h4 {
          color: rgba(255, 255, 255, 0); }
        .INDEX.four .display .flex .flex-child .sharp {
          position: absolute;
          bottom: 2px;
          right: 2px;
          z-index: 5;
          background: #fff;
          width: 198px;
          height: 45px; }
          .INDEX.four .display .flex .flex-child .sharp:before {
            content: "";
            width: 0;
            height: 0;
            border-bottom: 45px solid #fff;
            border-left: 21px solid transparent;
            position: absolute;
            right: 198px;
            bottom: 0; }
          .INDEX.four .display .flex .flex-child .sharp p {
            line-height: 45px;
            font-size: 15px;
            padding-left: 22px;
            margin: 0;
            color: #00A448; }
            .INDEX.four .display .flex .flex-child .sharp p::after {
              content: url(/images/index/sharp.png);
              position: absolute;
              bottom: 1px;
              right: 20px; }
    .INDEX.four .display .flex-width {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 93%;
      border: 2px solid #00a549;
      margin: auto; }
      .INDEX.four .display .flex-width h4 {
        text-align: center; }
      .INDEX.four .display .flex-width dl {
        margin: 2% 3%; }
        @media screen and (max-width: 768px) {
          .INDEX.four .display .flex-width dl {
            margin: 8% 3%; } }
        .INDEX.four .display .flex-width dl dd {
          text-align: center;
          font-size: 20px;
          margin-top: 12px;
          font-weight: 600; }
        @media screen and (max-width: 768px) {
          .INDEX.four .display .flex-width dl dt iframe {
            width: 100%;
            height: 220px; } }

.INDEX.five {
  padding: 64px; }
  @media screen and (max-width: 768px) {
    .INDEX.five {
      padding: 32px; } }
  .INDEX.five .display {
    max-width: 1210px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .INDEX.five .display .btn {
      width: 39%;
      padding: 0;
      box-shadow: none; }
      @media screen and (max-width: 768px) {
        .INDEX.five .display .btn {
          width: 100%;
          margin: 14px; } }
      .INDEX.five .display .btn a {
        text-decoration: none;
        font-size: 24px;
        font-weight: 800;
        color: #fff;
        background: #009E01;
        width: 400px;
        height: 70px;
        line-height: 70px;
        padding-right: 20px;
        display: inline-block;
        border-radius: 8px;
        position: relative;
        box-shadow: 0px 4px 0 #007500;
        transition: 0.2s all ease 0s; }
        @media screen and (max-width: 768px) {
          .INDEX.five .display .btn a {
            width: 290px; } }
        .INDEX.five .display .btn a:hover {
          box-shadow: none;
          transform: translate3d(0, 3px, 0); }
        .INDEX.five .display .btn a::after {
          content: url(/images/index/next.png);
          position: absolute;
          top: 5px;
          right: 34px; }

.INDEX.six {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .INDEX.six .item {
    background: #C6E5C3;
    width: 50%;
    padding: 48px 0px 48px 93px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .INDEX.six .item {
        width: 100%;
        padding: 24px; } }
    .INDEX.six .item:first-child::after {
      content: "";
      position: absolute;
      top: 35%;
      font-size: 18px;
      font-weight: bold;
      color: #31682E; }
      @media screen and (max-width: 768px) {
        .INDEX.six .item:first-child::after {
          top: 23%; } }
    .INDEX.six .item:last-child {
      background: #456E42; }
      @media screen and (max-width: 768px) {
        .INDEX.six .item:last-child {
          padding: 60px 30px !important; } }
    .INDEX.six .item figure {
      width: 245px;
      margin: 4px 0 0; }
    .INDEX.six .item .flex {
      display: flex;
      margin-top: 74px; }
      @media screen and (max-width: 768px) {
        .INDEX.six .item .flex {
          flex-wrap: wrap;
          margin-top: 18px; } }
      @media screen and (max-width: 768px) {
        .INDEX.six .item .flex dl {
          margin-top: 26px; } }
      .INDEX.six .item .flex dl dt {
        background: #2F692C;
        display: inline-block;
        padding: 4px 16px;
        color: #fff;
        border-radius: 16px;
        font-weight: 500;
        font-size: 16px; }
      .INDEX.six .item .flex dl dd {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500; }
      .INDEX.six .item .flex dl:last-child {
        margin-left: 6%; }
        @media screen and (max-width: 768px) {
          .INDEX.six .item .flex dl:last-child {
            margin: 26px 0 0 0 !important; } }
    .INDEX.six .item:last-child {
      padding: 76px 0 46px 160px; }
      .INDEX.six .item:last-child .right dl dt {
        font-size: 44px;
        padding-left: 60px;
        color: #F5D600;
        position: relative; }
        @media screen and (max-width: 768px) {
          .INDEX.six .item:last-child .right dl dt {
            font-size: 38px; } }
        .INDEX.six .item:last-child .right dl dt::after {
          content: url(/images/index/tel.png);
          position: absolute;
          top: 3px;
          left: 0; }
        .INDEX.six .item:last-child .right dl dt::before {
          content: attr(data-eng);
          display: block;
          color: #fff;
          font-size: 16px;
          position: absolute;
          top: -20px;
          left: 64px; }
      .INDEX.six .item:last-child .right dl dd {
        display: inline-block;
        margin: 0px 0px 20px 65px;
        color: #fff;
        font-size: 14px; }
      .INDEX.six .item:last-child .right p {
        font-size: 14px;
        color: #fff;
        margin-left: 16px;
        margin-top: 14px; }
      .INDEX.six .item:last-child .right .btn {
        padding: 0; }
        .INDEX.six .item:last-child .right .btn a {
          text-decoration: none;
          font-size: 16px;
          font-weight: 800;
          color: #5E8036;
          background: #fff;
          width: 320px;
          height: 50px;
          line-height: 50px;
          padding-right: 20px;
          padding-left: 60px;
          display: inline-block;
          border-radius: 8px;
          position: relative; }
          .INDEX.six .item:last-child .right .btn a::after {
            content: url(/images/index/mail-2.png);
            position: absolute;
            top: 5px;
            left: 20px; }

.INDEX dl, .INDEX dt, .INDEX dd {
  border: none;
  padding: 0;
  margin: 0; }

.INDEX h3 {
  border: none;
  text-align: center;
  box-shadow: none; }
  .INDEX h3::after {
    border: none; }

.INDEX h4 {
  background: rgba(255, 255, 255, 0);
  font-size: 24px;
  font-weight: bold; }

.CONTACT.first .formStyle {
  border: none;
  border-collapse: collapse;
  width: 100%;
  font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em; }
  .CONTACT.first .formStyle tbody tr th, .CONTACT.first .formStyle tbody tr td {
    padding: 32px 4px;
    border-bottom: #DDD 1px solid;
    text-align: left;
    vertical-align: middle; }
    @media only screen and (max-width: 768px) {
      .CONTACT.first .formStyle tbody tr th, .CONTACT.first .formStyle tbody tr td {
        padding: 14px 2px; } }
  .CONTACT.first .formStyle tbody tr th {
    width: 22%;
    text-align: left; }
    @media only screen and (max-width: 768px) {
      .CONTACT.first .formStyle tbody tr th {
        width: 100%;
        font-size: 16px; } }
    .CONTACT.first .formStyle tbody tr th.hiss {
      position: relative;
      top: 0;
      left: 0; }
      .CONTACT.first .formStyle tbody tr th.hiss:before {
        content: '必須';
        display: inline-block;
        padding: 4px 12px;
        color: #FFF;
        background: #354f00;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -16px 0 0;
        border-radius: 4px; }
        @media only screen and (max-width: 768px) {
          .CONTACT.first .formStyle tbody tr th.hiss:before {
            font-size: 12px;
            margin: -14px 0 0; } }
  .CONTACT.first .formStyle tbody tr td {
    padding-left: 40px; }
    @media only screen and (max-width: 768px) {
      .CONTACT.first .formStyle tbody tr td {
        padding-left: 0; } }
    .CONTACT.first .formStyle tbody tr td input[type=text],
    .CONTACT.first .formStyle tbody tr td input[type=tel],
    .CONTACT.first .formStyle tbody tr td input[type=email],
    .CONTACT.first .formStyle tbody tr td textarea {
      display: block;
      background: #f2f2f2;
      border: none;
      border-radius: 4px;
      font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.024em;
      font-size: 15px;
      width: 100%;
      max-width: 100%;
      padding: 8px;
      -webkit-transition: all 160ms 0ms ease-in-out;
      -moz-transition: all 160ms 0ms ease-in-out;
      -ms-transition: all 160ms 0ms ease-in-out;
      -o-transition: all 160ms 0ms ease-in-out;
      transition: all 160ms 0ms ease-in-out; }
      .CONTACT.first .formStyle tbody tr td input[type=text]:focus,
      .CONTACT.first .formStyle tbody tr td input[type=tel]:focus,
      .CONTACT.first .formStyle tbody tr td input[type=email]:focus,
      .CONTACT.first .formStyle tbody tr td textarea:focus {
        transform: scale(1.01);
        background: #FFF;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.16); }
    .CONTACT.first .formStyle tbody tr td label {
      vertical-align: middle; }
      .CONTACT.first .formStyle tbody tr td label input {
        display: inline-block;
        margin: 0 4px 0 0; }
    .CONTACT.first .formStyle tbody tr td textarea {
      height: 200px; }
  @media only screen and (max-width: 768px) {
    .CONTACT.first .formStyle {
      display: block; }
      .CONTACT.first .formStyle tbody, .CONTACT.first .formStyle tr, .CONTACT.first .formStyle th, .CONTACT.first .formStyle td {
        display: block; } }

.CONTACT.first .send {
  margin: 32px 0 0;
  text-align: center; }
  .CONTACT.first .send input {
    border: none;
    width: 240px;
    height: 48px;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    background: #517800;
    cursor: pointer; }
    .CONTACT.first .send input:hover {
      background: #436300; }
