@charset "utf-8";
.section{

}
.CONTACT{
	&.first{
		.formStyle{
			border:none;
			border-collapse:collapse;
			width:100%;
			@include font();
			tbody{
				tr{
					th,td{
						padding:32px 4px;
						border-bottom:$border 1px solid;
						text-align:left;
						vertical-align:middle;
						@media only screen and (max-width:768px){
							padding:14px 2px;
						}
					}
					th{
						width:22%;
						text-align:left;
						@media only screen and (max-width:768px){
							width:100%;
							font-size:16px;
						}
						&.hiss{
							position:relative;
							top:0;
							left:0;
							&:before{
								content:'必須';
								display:inline-block;
								padding: 4px 12px;
								color:#FFF;
								background:darken($green,12%);
								position: absolute;
								top: 50%;
								right: 0;
								margin: -16px 0 0;
								border-radius:4px;
								@media only screen and (max-width:768px){
									font-size:12px;
									margin:-14px 0 0;
								}
							}
						}
					}
					td{
						padding-left:40px;
						@media only screen and (max-width:768px){
							padding-left:0;
						}
						input[type=text],
						input[type=tel],
						input[type=email],
						textarea{
							display:block;
							background:#f2f2f2;
							border:none;
							border-radius:4px;
							@include font();
							font-size:15px;
							width:100%;
							max-width: 100%;
							padding:8px;
							@include transition(160ms);
							&:focus{
								transform: scale(1.01);
								background:#FFF;
								box-shadow:0 0 12px rgba(0,0,0,.16);
							}
						}
						label{
							vertical-align:middle;
							input{
								display:inline-block;
								margin:0 4px 0 0;
							}
						}
						textarea{
							height:200px;
						}
					}
				}
			}
			@media only screen and (max-width:768px){
				display:block;
				tbody,tr,th,td{
					display:block;
				}
			}
		}
		.send{
			margin:32px 0 0;
			text-align:center;
			input{
				border:none;
				width:240px;
				height:48px;
				@include font();
				color:#FFF;
				font-size:20px;
				font-weight:700;
				background:darken($green,4%);
				cursor:pointer;
				&:hover{
					background:darken($green,8%);
				}
			}
		}
	}
}